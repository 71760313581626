import apiAxios from "@/api-axios";

const url = '/dispute_schemas/';

export default {
  actions: {
    async apiGetDisputeSchemas({ dispatch }, params) {
      try {
        return (await apiAxios.get(url, params)).data;
      }catch (e) {
        dispatch('catchError', e)
      }
      return [];
    },
    async apiCreateDisputeSchema({ dispatch }, data) {
      try {
        return (await apiAxios.post(url, data)).data;
      }catch (e) {
        dispatch('catchError', e)
      }
      return {};
    },
    async apiUpdateDisputeSchema({ dispatch }, {id, data}) {
      try {
        return (await apiAxios.patch(`${url}${id}`, data)).data;
      }catch (e) {
        dispatch('catchError', e)
      }
      return {};
    },
    async apiDeleteDisputeSchema({ dispatch }, id) {
      try {
        return (await apiAxios.delete(`${url}${id}`)).data;
      }catch (e) {
        dispatch('catchError', e)
      }
      return '';
    }
  }
}
