import apiAxios from "@/api-axios";

export default {
  actions: {
    async apiEditTask({dispatch}, {taskId, startAt}) {
      try {
        return (await apiAxios.patch(`/tasks/${taskId}`, {startAt})).data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return '';
    },
    async apiDeleteTask({dispatch}, taskId) {
      try {
        return (await apiAxios.delete(`/tasks/${taskId}`)).data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return '';
    },
  }
}
