import apiAxios from "@/api-axios";

export default {
  store: {
    disputeTypes: [],
  },
  mutations: {
    setDisputeTypes(state, disputeTypes) {
      state.disputeTypes = disputeTypes;
    },
  },
  getters: {
    disputeTypes: s=>s.disputeTypes
  },
  actions: {
    async apiDisputeTaskUploadFile({dispatch}, [task_id, formData]) {
      try {
        const response = await apiAxios.axios.post(`/disputes/tasks/${task_id}/file`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiDisputeTaskConfirmFile({dispatch}, {file_id}) {
      try {
        return await apiAxios.patch(`/disputes/tasks/file/${file_id}/confirm`);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiDisputeTaskDeleteFile({dispatch}, {file_id}) {
      try {
        return await apiAxios.delete(`/disputes/tasks/file/${file_id}`);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiSendTaskCode({dispatch}, {task_id, code, codeName}) {
      try {
        if(!codeName) {
          codeName = 'code';
        }
        const response = await apiAxios.put(`/disputes/tasks/${task_id}/code?${codeName}=${code}`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiCreateDisputesTypes({dispatch}, params) {
      try {
        return await apiAxios.post('/disputes/type', params);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiPatchDisputesType({dispatch}, params) {
      try {
        const id = params.id;
        delete params.id;
        return await apiAxios.patch(`/disputes/type/${id}`, params);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiAddDisputeTypePrice({dispatch}, params) {
      try {
        return await apiAxios.post('/disputes/type/price/', params);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetDisputeTypes({dispatch, commit}){
      try {
        const response = await apiAxios.get('/disputes/types');
        commit('setDisputeTypes', response.data);
        return response;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiDeleteDisputeType({dispatch}, dispute_type_id){
      try {
        return await apiAxios.delete(`/disputes/type/${dispute_type_id}`);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiCreateDisputes({dispatch}, params) {
      try {
        const response = await apiAxios.post('/disputes/', params);
        dispatch('apiLoadOrderMe');
        return response;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetDisputeData({dispatch}, dispute_id){
      let data = {};
      try {
        const response = await apiAxios.get(`/disputes/${dispute_id}`);
        data = response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return data;
    },
    async apiDeleteDispute({dispatch}, dispute_id){
      try {
        return await apiAxios.delete(`/disputes/${dispute_id}`);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiAutoUpdateDispute({dispatch}, {dispute_id, is_auto}){
      try {
        return await apiAxios.patch(`/disputes/${dispute_id}/auto`, {is_auto});
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiUpdateDisputeTask({dispatch}, {task_id, params}){
      try {
        return await apiAxios.patch(`/disputes/tasks/${task_id}`, params)
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiStartDisputeTask({dispatch}, {task_id}){
      try {
        return await apiAxios.get(`/disputes/tasks/${task_id}/start`)
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiRestartDisputeTask({dispatch}, {task_id}){
      try {
        return await apiAxios.get(`/disputes/tasks/${task_id}/restart`)
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetLocalDisputes({dispatch}, params) {
      try {
        if (params?.userName && !params?.userName.trim().length) {
          delete params.userName;
        }
        if (!params.bureau_id) {
          delete params.bureau_id;
        }
        if (!params.operator_id) {
          delete params.operator_id;
        }
        if (!params.last_days) {
          delete params.last_days;
        }

        return (await apiAxios.get('/disputes/local/', params)).data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {
        total: 0,
        disputes: [],
      }
    },
    async apiGetLocalDisputesStatuses({dispatch}, params) {
      try {
        return (await apiAxios.get('/disputes/local/status/', params, apiAxios.getParamsSerializerConfig())).data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {
        total: 0,
        disputes: [],
      }
    },
    async apiGetLocalDisputeIsUpdate({dispatch}, params) {
      try {
        if (!params.lastUpdatedAt.length) {
          delete params.lastUpdatedAt;
        }
        return (await apiAxios.get('/disputes/local/update/', params)).data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {
        lastUpdatedAt: (new Date()).toISOString(),
        disputeIds: [],
      }
    },
    async apiDeleteLocalDispute({dispatch}, disputeId) {
      try {
        return (await apiAxios.delete(`/disputes/local/${disputeId}`)).data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return '';
    },
    async apiGetOpenLocalDisputes({dispatch},params){
      params.status = 'open';
      return await dispatch('apiGetLocalDisputes', params)
    },
    async apiGetCloseLocalDisputes({dispatch},params){
      params.status = 'close';
      return await dispatch('apiGetLocalDisputes', params)
    },
    async apiGetLocalDispute({dispatch}, disputeId){
      try {
        return (await apiAxios.get(`/disputes/local/${disputeId}`)).data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {}
    },
    async apiCreateLocalDispute({dispatch}, data){
      try {
        return (await apiAxios.post('/disputes/local/', data)).data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {}
    }
  }
}
